import React from "react";
import { PageLayout, TrustedBy } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import Margins from "@components/Margins/Margins";
import { Hero } from "@components/Hero/Hero";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import FeatureCard, { FeatureCardLayout, FeatureCardProps } from "@components/FeatureCard/FeatureCard";

export default function Dashboard() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "alerts",
			}}
		>
			<Margins className={styles.margins}>
				<HeroSection />
				<PersonalizedOversight />
				<TailoredTracking />
				<EfficientManagement />
				<JoinInstitutionsV2 outerContainerClassName={styles.outerContainerClassName} />
			</Margins>
		</PageLayout>
	);
}


// New component redesign starts here
const HeroSection = () => {
	const hero = {
		title: "Fine-tuned stock tracking for biopharma research",
		description:
			"Optimize your stock management with dynamic tracking tools for samples, reagents, and consumables",
		image: (
			<StaticImage
				src="../../../assets/products/platform-dashboard.png"
				alt="A Central Protocol Hub for Smooth Collaboration"
			/>
		),
	};
	return (
		<Hero className={styles.hero} {...hero} heroTextContainerSize="small" tabletTextAlignment="center" />
	)
}

const PersonalizedOversight = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Personalized oversight for key stock items",
			image: (
				<StaticImage
					src={"../../../assets/products/orders-product.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Keep a close eye on selected samples, reagents, and consumables crucial to your research",
				"Adjust tracking settings based on specific storage locations, whether it’s a freezer, shelf, or box",
				"Get a clear preview of contents so that you can make faster, better decisions and accurately assess your stock",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.personalizedOversightContainer} {...card} key={index} orientation="right" />;
			})}
		</FeatureCardLayout>
	)
}

const TailoredTracking = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Tailored tracking for precision",
			image: (
				<StaticImage
					src={"../../../assets/products/stock-trackers.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Easily track items, whether they’re samples or consumables, on both shared and individual platforms",
				"Where project management meets electronic lab notebook",
				"Integrate your research workflows and database",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.container} {...card} key={index} orientation="left" />;
			})}
		</FeatureCardLayout>
	)
}

const EfficientManagement = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Efficient management of samples, reagents, and restocks",
			image: (
				<StaticImage
					src={"../../../assets/products/freezer-advanced-search-image.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Quickly locate samples or reagents, view storage details, and initiate reorder processes when low",
				"Keep your research flowing smoothly by always having both samples and consumables at your fingertips",
				"Streamline the restocking process so you never run out of essential items in the middle of critical research",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.efficientManagementCards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.container} {...card} key={index} orientation="right" />;
			})}
		</FeatureCardLayout>
	)
}